/**
 * Get the width of the container in REM (No not the band)
 * @param selector div selector
 */
export const getWidthREM = (selector: string) => {
    const container: HTMLDivElement | null = document.querySelector(selector);
    if (container === null) {
        return 0;
    }

    const size = container.offsetWidth;

    const returnValue = size / parseFloat(window.getComputedStyle(container).fontSize);

    return returnValue
};