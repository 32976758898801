import Container from "react-bootstrap/Container";
import FooterNavItem from "../components/navigation/FooterNavItem";

const Footer = () => {
    return (
        <footer className="bg-body-tertiary">
            <Container className="d-flex flex-wrap justify-content-between align-items-center py-3">
                <p className="col-md-4 mb-0 text-body-secondary">© Owen Ryan 2023 | Icons and logo by FontAwesome</p>
                <ul className="nav col-md-4 justify-content-end">
                    <FooterNavItem text="About" url="https://owenryan.us/informinator"/>
                    <FooterNavItem text="Roadmap" url="/roadmap.html"/>
                    <FooterNavItem text="Source Code" url="https://code.owenryan.us/owenryan/informinator"/>
                </ul>
            </Container>
        </footer>
    );
};

export default Footer;