import Card from "react-bootstrap/Card";
import {Article} from "../../Interfaces";
import "./Cards.sass";
import CoreArticleCard from "./CoreArticleCard";

type ArticleCardProps = { article: Article }

export const ArticleCard = ({article}: ArticleCardProps) => {
    return (
        <CoreArticleCard footerUrl={article.url}>
            <Card.Title>{article.title}</Card.Title>
            <Card.Text>{article.body}</Card.Text>
        </CoreArticleCard>
    );
};

export default ArticleCard;