type FooterNavItemProps = { text: string, url: string }

const FooterNavItem = ({text, url}: FooterNavItemProps) => {
    return (
        <li className="nav-item">
            <a className="nav-link px-2 text-body-secondary" href={url}>{text}</a>
        </li>
    )
};

export default FooterNavItem;