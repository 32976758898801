import {Article, FeedInterface, FeedStatus, Source} from "../Interfaces";
import {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {widthContext} from "../contexts";
import {fetchArticles} from "../fetchers";
import ArticleCard from "../components/cards/ArticleCard";
import PlaceholderArticleCard from "../components/cards/PlaceholderArticleCard";
import FeedHeader from "../components/feed/FeedHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import FeedCarousel from "../components/feed/FeedCarousel";

function chunked<Type>(inputList: Type[], chunkSize: number): Type[][] {
    const chunks: Type[][] = [];

    for (let i = 0; i < inputList.length; i += chunkSize) {
        const chunk = inputList.slice(i, i + chunkSize);
        chunks.push(chunk);
    }

    return chunks;
}

type FeedProps = { feed: FeedInterface, parentSource: Source }

export const NewsFeed = ({feed, parentSource}: FeedProps) => {
    const [status, setStatus] = useState(FeedStatus.Loading);
    const [articles, setArticles] = useState<Article[]>([]);
    const width = useContext<number>(widthContext);

    useEffect(() => {
        const getArticles = async () => {
            let articles;
            try {
                articles = await fetchArticles(feed.url);
            } catch (NetworkError) {
                try {
                    articles = await fetchArticles(feed.url, "https://informinator.owenryan.us/proxy");
                } catch (NetworkError) {
                    setStatus(FeedStatus.Error);
                    return
                }
            }

            setArticles(articles);
            setStatus(FeedStatus.Loaded);
        };

        getArticles();
    }, [feed.url]);

    const chunks = useMemo(() => {
        const articleCards = articles.map(article => <ArticleCard article={article}/>);

        const chunkSize = Math.floor(width / 18);
        // TODO: Resizing the page preserves the page number, leading to a possible out-of-index error
        let chunks: ReactNode[][];
        if (status === FeedStatus.Loaded) {
            chunks = chunked(articleCards, chunkSize);
        } else {
            chunks = [[]];
            for (let i = 0; i < chunkSize; i++) {
                chunks[0].push(<PlaceholderArticleCard/>)
            }
        }

        return chunks;
    }, [width, articles, status]);

    return (
        <div>
            <FeedHeader source={parentSource} feed={feed} status={status}/>
            {status === FeedStatus.Error &&
                <p><FontAwesomeIcon icon={faTriangleExclamation}/> Something went wrong while fetching articles.</p>}
            <FeedCarousel articleCardChunks={chunks} source={parentSource} feed={feed}/>
        </div>
    );
};

export default NewsFeed;