import Spinner from "react-bootstrap/Spinner";
import {FeedInterface, Source, FeedStatus} from "../../Interfaces";

type FeedHeaderProps = { source: Source, feed: FeedInterface, status: FeedStatus }

export const FeedHeader = ({source, feed, status}: FeedHeaderProps) => {
    return (
        <h3>
            {source.name} - {feed.name} {status === FeedStatus.Loading && <Spinner variant="primary"/>}
        </h3>
    )
};

export default FeedHeader;