import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import "./Cards.sass";
import CoreArticleCard from "./CoreArticleCard";

export const PlaceholderArticleCard = () => {
    return (
        <CoreArticleCard footerUrl={null}>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6}/>
                <Placeholder xs={4}/>
                <Placeholder xs={3}/>
                <Placeholder xs={5}/>
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={10}/>
                <Placeholder xs={12}/>
                <Placeholder xs={7}/>
            </Placeholder>
        </CoreArticleCard>
    )
};

export default PlaceholderArticleCard;