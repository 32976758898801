import {Article, Source} from "./Interfaces";
import XMLToArticles from "./parser";

export const fetchArticles = async (url: string, proxy: string | null = null): Promise<Article[]> => {
    let response;
    if (proxy === null) {
        response = await fetch(url, {referrerPolicy: "no-referrer"});
    } else {
        response = await fetch(`${proxy}?url=${url}`);
    }

    // TODO: Also check MIME Type
    if (!response.ok) {
        throw Error("improper response code");
    }

    // TODO: Integrity check to make sure array is not empty
    const rawText = await response.text();
    const articles = XMLToArticles(rawText);
    if (!articles) {
        throw Error("Article array null or empty");
    }
    return articles;
};

export const fetchSources = async (): Promise<Source[]> => {
    const response = await fetch("/feeds.json");
    return await response.json();
};
