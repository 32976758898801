import {useState} from "react";
import Container from "react-bootstrap/Container";
import Header from "./views/Header";
import Newsroom from "./views/Newsroom";
import Footer from "./views/Footer";
import {categoryContext} from "./contexts";
import './App.sass';

export const App = () => {
    const [category, setCategory] = useState('main');
    return (
        <categoryContext.Provider value={{category: category, setCategory: setCategory}}>
            <Header/>
            <Container id="mainContainer" className="py-3">
                <Newsroom/>
            </Container>
            <Footer/>
        </categoryContext.Provider>
    );
};

export default App;