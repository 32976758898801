import {ReactNode, useEffect, useMemo, useState} from "react";
import {FeedInterface, Source} from "../../Interfaces";
import {NextButton, PreviousButton} from "./CarouselButtons";
import {Carousel} from 'bootstrap';

type FeedCarouselProps = { articleCardChunks: ReactNode[][], source: Source, feed: FeedInterface }

const FeedCarousel = ({articleCardChunks, source, feed}: FeedCarouselProps) => {
    const [carousel, setCarousel] = useState<Carousel | null>(null);
    const carouselId = useMemo(() => `${source.id}${feed.id}carousel`, [source, feed]);

    useEffect((): void => {
        const bootstrapCarousel = new Carousel(`#${carouselId}`);
        setCarousel(bootstrapCarousel);
        console.log(`Carousel created with id '${carouselId}'`)
    }, [carouselId]);

    return (
        <div id={carouselId} className="carousel slide">
            <div className="carousel-inner">
                {articleCardChunks.map(chunk => (
                    <div className={`carousel-item ${articleCardChunks.indexOf(chunk) === 0 && 'active'}`}>
                        <div className="cards-wrapper card-group"
                             style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="row g-4 mb-3">
                                {chunk.map(card => card)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {carousel !== null &&
                <>
                    <PreviousButton carousel={carousel}/>
                    <NextButton carousel={carousel}/>
                </>
            }
        </div>
    )
};

export default FeedCarousel;