import {ReactNode} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

type CoreArticleCardProps = { children: ReactNode, footerUrl: string | null }

export const CoreArticleCard = ({children, footerUrl}: CoreArticleCardProps) => {
    return (
        <Card className="article-card p-0 mx-2">
            <Card.Body className="article-card-body">{children}</Card.Body>
            <Card.Footer className="card-flooter-left">
                <Button variant="outline-success" as="a"
                        className={`card-footer-button ${(footerUrl === null ? "disabled" : "")}`}
                        href={(footerUrl ? footerUrl : "")}>Read</Button>
            </Card.Footer>
        </Card>
    )
};

export default CoreArticleCard;