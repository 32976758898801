import {Article} from "./Interfaces";

/* Parse an RSS or Atom feed item into an `Article` object */
const ItemToArticle = (item: Element): Article => {
    const article: Article = Object.create({});

    // @ts-ignore
    article.title = item.querySelector("title").textContent;
    // TODO: Some characters such as apostrophes are urlencoded here and need to be decoded
    // @ts-ignore
    article.body = item.querySelector("description").textContent;

    // Shorten article body since some news sources put entire articles in RSS Feeds (Thank you The Guardian)
    // TODO: This should be done when rendering card, allowing user to customise max character limit
    if (article.body.length > 250) {
        article.body = `${article.body.slice(0, 97)}...`;
    }

    // @ts-ignore
    article.url = item.querySelector("link").textContent;
    return article
};

/* Parse items (articles) from an RSS or Atom feed */
export const XMLToArticles = (text: string): Article[] => {
    // Initialise parser and load document
    const parser: DOMParser = new DOMParser();
    const document: Document = parser.parseFromString(text, "application/xml");

    // Query the document for RSS items
    let rawArticles = document.querySelectorAll("item");

    // If there are none, check for any Atom items (entries)
    if (rawArticles.length === 0) {
        rawArticles = document.querySelectorAll("entry");
    }

    // Map the NodeList of items to the ItemToArticle parser function
    // https://stackoverflow.com/a/53350032
    return Array.from(rawArticles).map(ItemToArticle);
};

export default XMLToArticles;