export interface Article {
    title: string;
    body: string;
    url: string;
}

export interface FeedInterface {
    id: string
    name: string
    url: string
    tags: Array<string>
}

export interface Source {
    id: string
    name: string
    feeds: Array<FeedInterface>
}

export enum FeedStatus {
    Loading,
    Loaded,
    Error
}