import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import "./CarouselButtons.sass";
import {Carousel} from "bootstrap/index";

type ButtonProps = { carousel: Carousel }

export const NextButton = ({carousel}: ButtonProps) => {
    return (
        <button className="carousel-control-next" type="button" onClick={() => carousel.next()} data-bs-slide='next'>
            <FontAwesomeIcon icon={faArrowRight}/>
            <span className="visually-hidden">Next</span>
        </button>
    )
};

export const PreviousButton = ({carousel}: ButtonProps) => {
    return (
        <button className="carousel-control-prev" type="button" onClick={() => carousel.prev()} data-bs-slide='prev'>
            <FontAwesomeIcon icon={faArrowLeft}/>
            <span className="visually-hidden">Previous</span>
        </button>
    )
};