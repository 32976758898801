import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useContext} from "react";
import {categoryContext} from "../../contexts";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type HeaderNavCategoryProps = { name: string, icon: IconProp, categoryName: string }

const HeaderNavCategory = ({name, icon, categoryName}: HeaderNavCategoryProps) => {
    const {category, setCategory} = useContext(categoryContext);
    return (
        <Nav.Link onClick={() => setCategory(categoryName)} className={categoryName === category ? "enabled" : ""}>
            <FontAwesomeIcon icon={icon} className="bi me-2"/>
            {name}
        </Nav.Link>
    )
};

export default HeaderNavCategory;