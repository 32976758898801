import {useEffect, useState} from "react";
import {Source} from "../Interfaces";
import Alert from "react-bootstrap/Alert";
import NewsSource from "./NewsSource";
import Spinner from "react-bootstrap/Spinner";
import {widthContext} from "../contexts";
import {getWidthREM} from "../screen";
import {fetchSources} from "../fetchers";

enum SourceStatus {
    fetching,
    loaded
}

export const Newsroom = () => {
    const [sources, setSources] = useState<Source[]>([]);
    const [sourceStatus, setSourceStatus] = useState(SourceStatus.fetching);
    const [width, setWidth] = useState(4);

    useEffect((): void => {
        const fetch = async () => {
            try {
                setSources(await fetchSources());
                setSourceStatus(SourceStatus.loaded);
            } catch (e) {
                console.log(e)
            }
        };
        // TODO: Store this config file in a cookie

        fetch();
    }, []);

    useEffect(() => {
        // This runs after the DOM is rendered, so #mainContainer can ge queried
        const newWidth = getWidthREM("#mainContainer");
        setWidth(newWidth);

        // Add an event listener to automatically change the width when the window is resized
        window.addEventListener("resize", () => {
            const newWidth = getWidthREM("#mainContainer");
            setWidth(newWidth);
        });
        // TODO: Clean up the events when component unmounts
    }, []);

    return (
        <widthContext.Provider value={width}>
            <Alert variant="warning">Informinator is still under development. Some features my not function correctly</Alert>
            {sourceStatus === SourceStatus.loaded ? (
                <>
                    {sources.map(source => <NewsSource key={source.id} source={source}/>)}
                </>
            ) : (
                <>
                    <Spinner animation="border" variant="primary" size="sm"/>
                    <strong> Loading sources</strong>
                </>
            )}
        </widthContext.Provider>
    )
};

export default Newsroom;