import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBullhorn,
    faCommentDots,
    faEarthAfrica,
    faHotdog,
    faMicrochip,
    faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import HeaderNavCategory from "../components/navigation/HeaderNavCategory";

export const Header = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                    <FontAwesomeIcon icon={faNewspaper} className="bi me-2"/>
                    Informinator
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" role="basic-navbar-nav">
                    <Nav className="me-auto">
                        <HeaderNavCategory name="Headlines" icon={faBullhorn} categoryName="main"/>
                        <HeaderNavCategory name="World" icon={faEarthAfrica} categoryName="world"/>
                        <HeaderNavCategory name="United States" icon={faHotdog} categoryName="us"/>
                        <HeaderNavCategory name="Opinions" icon={faCommentDots} categoryName="opinion"/>
                        <HeaderNavCategory name="Technology" icon={faMicrochip} categoryName="tech"/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default Header;