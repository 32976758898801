import {Source} from "../Interfaces";
import {useContext} from "react";
import {categoryContext} from "../contexts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NewsFeed from "./NewsFeed";

type SourceBodyProps = { source: Source }

export const SourceBody = ({source}: SourceBodyProps) => {
    const {category} = useContext(categoryContext);

    // Filter feeds with the current category tag
    const includedFeeds = source.feeds.filter(feed => feed.tags.includes(category));

    // Do not render anything if there are no feeds with the current tag
    if (includedFeeds.length === 0) {
        return null;
    }
    return (
        <Row>
            <Col className="my-1">
                {includedFeeds.map(feed => <NewsFeed key={feed.id} feed={feed} parentSource={source}/>)}
            </Col>
        </Row>
    );
};

export default SourceBody;